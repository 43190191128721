import { FC } from 'react'

import { DIALOG_NAME } from 'const/dialogs'

import { MaxPayoutMarketProperty } from './max-payout-market-property'
import { TermMarketProperty } from './term-market-property'
import { TriggerMarketProperty } from './trigger-market-property'
import { OrderTerms } from '../../api/nest/orders/types'

export interface MainOrderPropertyProps {
  className?: string
  classNameDescription?: string
  classNameIcon?: string
  withValue?: boolean
  blocked?: boolean
  marketInfo?: {
    maxPayout?: number
    term?: OrderTerms
    trigger?: number
  }
  includeMaxPayout?: boolean
  includeTrigger?: boolean
  includeTerm?: boolean
  withEditBtn?: boolean
  dialogName?: DIALOG_NAME
  withEditMaxPayout?: boolean
}

//TODO: Add support tooltip from property description
export const MainMarketPropertyList: FC<MainOrderPropertyProps> = ({
  includeMaxPayout,
  includeTerm,
  includeTrigger,
  marketInfo,
  dialogName,
  withEditMaxPayout = false,
  ...rest
}) => {
  if (!marketInfo) return null

  return (
    <>
      {includeMaxPayout && (
        <MaxPayoutMarketProperty
          value={marketInfo.maxPayout}
          withEditBtn={withEditMaxPayout}
          {...rest}
          dialogName={dialogName}
        />
      )}
      {includeTrigger && (
        <TriggerMarketProperty
          value={marketInfo.trigger}
          {...rest}
          withEditBtn={false}
          withLockIcon={true}
        />
      )}
      {includeTerm && (
        <TermMarketProperty
          value={marketInfo.term}
          {...rest}
          withEditBtn={false}
          withLockIcon={true}
        />
      )}
    </>
  )
}
