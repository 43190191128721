import { FC } from 'react'

import { MarketProperty } from './market-property'
import Icon from './images/trigger.svg'
import { MarketPropertyExtendsProps } from './market-property/market-property'
import { triggerOfOrderFormatter } from '../../utils'

export const TriggerMarketProperty: FC<MarketPropertyExtendsProps<number>> = ({
  value,
  ...rest
}) => {
  return (
    <MarketProperty
      description="Necessary change in the index that would result in&nbsp;a&nbsp;payout."
      Icon={Icon}
      label="Trigger"
      value={value ? triggerOfOrderFormatter(value) : '-'}
      {...rest}
    />
  )
}
