import { FC } from 'react'

import { usdFormatter } from 'utils'

import { MarketProperty } from './market-property'
import Icon from './images/max-payout.svg'
import { MarketPropertyExtendsProps } from './market-property/market-property'

export const MaxPayoutMarketProperty: FC<MarketPropertyExtendsProps<number>> = ({
  value,
  ...rest
}) => {
  return (
    <MarketProperty
      description="Highest amount potentially received under the&nbsp;contract."
      Icon={Icon}
      label="Maximum Payout"
      value={usdFormatter(value || 0)}
      {...rest}
    />
  )
}
