import { FC } from 'react'

import { cn, usdFormatter } from 'utils'

import classes from './cost.module.css'

interface ICost {
  details: JSX.Element
  totalPrice: number
  monthlyPrice: number
  totalPriceWithDiscount?: number
  monthlyPriceWithDiscount?: number
  className?: string
}

export const Cost: FC<ICost> = ({
  details,
  totalPrice,
  monthlyPrice,
  totalPriceWithDiscount,
  monthlyPriceWithDiscount,
  className,
}) => {
  const usdFormatBuilder = (val = 0) =>
    usdFormatter(val, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })

  const costValue = usdFormatBuilder(totalPrice)
  const costWithDiscount = usdFormatBuilder(totalPriceWithDiscount)
  const monthlyValue = usdFormatBuilder(monthlyPrice)
  const monthlyValueWithDiscount = usdFormatBuilder(monthlyPriceWithDiscount)

  const withDiscount = costValue !== costWithDiscount

  return (
    <div className={cn(classes.cost, className)}>
      <strong className={cn(classes.costValue, Boolean(withDiscount) && classes.oldCost)}>
        {costValue}
      </strong>
      {withDiscount ? (
        <strong className={cn(classes.costValue, classes.accentColor)}>{costWithDiscount}</strong>
      ) : null}

      <div>
        <p className={classes.annualDetails}>{details}</p>
        <p className={classes.monthly}>
          <span className={withDiscount ? classes.oldMonthlyCost : ''}>{monthlyValue}</span>
          {withDiscount ? (
            <span className={cn(classes.accentColor, classes.leftMargin)}>
              {monthlyValueWithDiscount}
            </span>
          ) : null}
          /month equivalent
        </p>
      </div>
    </div>
  )
}
