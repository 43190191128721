import { ComponentProps, CSSProperties, FC, memo } from 'react'

import { cn } from 'utils'
import { Heading } from 'components/heading'

import classes from './order-property.module.css'

interface PropertyProps {
  annually?: boolean
  description?: string
  bottomDescription?: string
  label: string
  className?: string
  minDescriptionRow?: number
  themeValue?: 'bold'
}

const _Property: FCRC<PropertyProps> = ({
  annually,
  description,
  bottomDescription,
  label,
  className,
  children,
  themeValue,
  minDescriptionRow = 2,
}) => (
  <div className={cn(classes.wrapper, className)}>
    <Heading theme="h5" component="h4" className={classes.label}>
      {label}
    </Heading>
    {description && (
      <span
        className={classes.description}
        style={{ '--minDescriptionRow': minDescriptionRow } as CSSProperties}
      >
        {description}
      </span>
    )}
    <span className={cn(classes.value, themeValue && classes[themeValue])}>{children}</span>
    {annually && <span className={classes.paymentPeriod}>annually</span>}
    <span className={classes.bottomDescription}>{bottomDescription}</span>
  </div>
)

export const Wrapper: FC<ComponentProps<'div'>> = ({ children, className }) => {
  return <div className={cn(classes.wrapper, className)}>{children}</div>
}

export const Description: FC<ComponentProps<'span'>> = ({ children, className }) => {
  return <span className={cn(classes.description, className)}>{children}</span>
}

export const Title: FC<ComponentProps<'h4'>> = ({ children, className }) => {
  return (
    <Heading theme="h5" component="h4" className={cn(classes.label, className)}>
      {children}
    </Heading>
  )
}

interface Value extends ComponentProps<'span'> {
  theme?: 'bold'
}

export const Value: FC<Value> = ({ children, className, theme }) => {
  return (
    <span className={cn(classes.value, classes[`${theme}ValueTheme`], className)}>{children}</span>
  )
}

export const OrderProperty = memo<ComponentProps<typeof _Property>>(_Property)
