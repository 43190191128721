import { FC, SVGProps } from 'react'

import * as OrderProperty from 'components/order-property'
import { Button } from 'components/button'
import { useControlRouterDialog } from 'components/dialog'
import { DIALOG_NAME } from 'const/dialogs'
import EditIcon from 'icons/edit-icon.svg'

import classes from './market-property.module.css'
import { cn } from '../../../utils'
import LockIcon from '../../../icons/lockIcon.svg'

interface MarketPropertyProps<TValue = string> {
  className?: string
  classNameDescription?: string
  label: string
  value: TValue
  description: string
  Icon: FC<SVGProps<SVGElement>>
  blocked?: boolean
  withValue?: boolean
  withEditBtn?: boolean
  classNameIcon?: string
  dialogName?: DIALOG_NAME
  withLockIcon?: boolean
}

export type MarketPropertyExtendsProps<TValue = string> = Omit<
  MarketPropertyProps<TValue>,
  'label' | 'description' | 'Icon' | 'value'
> & {
  value?: TValue
}

export const MarketProperty: FC<MarketPropertyProps> = ({
  className,
  classNameDescription,
  classNameIcon,
  label,
  Icon,
  description,
  value,
  blocked,
  withValue = true,
  withEditBtn,
  dialogName = DIALOG_NAME.searchMarketByZipCode,
  withLockIcon,
}) => {
  const { open } = useControlRouterDialog()

  return (
    <div className={cn(classes.wrapper, className)} key={label}>
      <div className={classes.leftSection}>
        <Icon className={cn(classes.icon, classNameIcon)} />
      </div>
      <OrderProperty.Wrapper>
        <div>
          <OrderProperty.Title>{label}</OrderProperty.Title>
          <OrderProperty.Description
            className={cn(blocked && classes.blocked, classNameDescription)}
          >
            {description}
          </OrderProperty.Description>
        </div>
        <div
          className={classes.editBtnWrapper}
          onClick={withEditBtn ? async () => await open(dialogName) : () => null}
        >
          {withValue && (
            <OrderProperty.Value
              className={cn(classes.maxValue, withEditBtn && classes.highlightedValue)}
            >
              {value}
            </OrderProperty.Value>
          )}
          {withEditBtn && (
            <Button theme="secondary" className={classes.editBtn}>
              <EditIcon />
              EDIT
            </Button>
          )}
          {withLockIcon && <LockIcon className={classes.lockIcon} />}
        </div>
      </OrderProperty.Wrapper>
    </div>
  )
}
