import { FC } from 'react'

import { MarketProperty } from './market-property'
import Icon from './images/term.svg'
import { MarketPropertyExtendsProps } from './market-property/market-property'

export const TermMarketProperty: FC<MarketPropertyExtendsProps> = ({ value, ...rest }) => {
  return (
    <MarketProperty
      description="Length of the contract, which is renewable on an annual&nbsp;basis."
      Icon={Icon}
      label="Term"
      value={value ?? '-'}
      {...rest}
    />
  )
}
